import { createSgDashboardPropsMapping, createPptExportPropsMapping } from '../common/SgDashboard';
import { widgetize, WidgetPropsMapping } from '@sg-widgets/react-core';

import { LicenseManager } from 'ag-grid-enterprise';
import { Grid } from 'ag-grid-community';
import { generateArclabAgGrid } from '../arclab-ag-grid-generator/arclab-ag-grid-generator';
import { asStringInitializedEnum } from '../common/Sgwt';
import { AgGridTheme } from 'sg-dashboard-sdk';
import { Props } from '../arclab-ag-grid-generator/api';
//
LicenseManager.setLicenseKey(
  'CompanyName=SHI International SAS_on_behalf_of_SOCIETE GENERALE (6 Allee des Sablons),LicensedApplication=SG Dashboard,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-007553,ExpiryDate=25_March_2021_[v2]_MTYxNjYzMDQwMDAwMA==2e1ec4baadc044541079c1eb57cc8414'
);

export const ArclabAgGrid = generateArclabAgGrid(Grid);

const tagName = 'arclab-ag-grid';
if (window.customElements && !window.customElements.get(tagName)) {
  widgetize(tagName, ArclabAgGrid, {
    ...createSgDashboardPropsMapping('output-event'),
    ...createPptExportPropsMapping(),
    query: WidgetPropsMapping.asObject(),
    withQuickFilter: WidgetPropsMapping.asObject(), // asObject() uses JSON.parse() which is ok for parsing "true"/"false" into boolean
    withExport: WidgetPropsMapping.asObject(),
    getArcLabPptExport: WidgetPropsMapping.asObject(),
    excelExportParams: WidgetPropsMapping.asObject(),
    agTheme: asStringInitializedEnum<Props, AgGridTheme>()
  });
}
